import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import CondensedTable from './condensed';

const ShortenedTable = ({ rows, ...otherProps }) => {
  const highlightedRow = find(rows, { is_highlighted: true });
  let selectedRows;
  if (highlightedRow) {
    // If we have a highlighted row we want to show the rows around it, to a total of 5 rows
    const { rank } = highlightedRow;
    let firstRank = rank - 3;
    let lastRank = rank + 2;

    // If the highlighted row is in the top 2 we need to show more after it to make up the 5
    if (rank < 3) {
      lastRank += 3 - rank;
    }

    // If the highlighted row is in the bottom 2 we need to show more before it to make up the 5
    if (rank > rows.length - 3) {
      firstRank -= 3 - (rows.length - rank);
    }

    selectedRows = rows.slice(Math.max(firstRank, 0), Math.min(lastRank, rows.length));
  } else {
    // No highlighted row, show first five
    selectedRows = rows.slice(0, 5);
  }

  return <CondensedTable rows={selectedRows} {...otherProps} />;
};

ShortenedTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      is_highlighted: PropTypes.bool,
    }),
  ).isRequired,
};

export default ShortenedTable;
