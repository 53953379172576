import { FlexContainer, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';

const Cell = ({ align, style, textStyle, justify, children, preset, color, weight }) => (
  <FlexContainer
    alignItems="center"
    justifyContent={justify}
    style={{
      whiteSpace: 'nowrap',
      ...style,
    }}
  >
    <Typography
      preset={preset}
      color={color}
      align={align}
      weight={weight}
      style={textStyle}
      component="div"
    >
      {children}
    </Typography>
  </FlexContainer>
);

Cell.defaultProps = {
  align: 'center',
  style: {},
  textStyle: {},
  justify: 'center',
  preset: 'subtitle--small',
  color: 'black',
  weight: 'normal',
};

Cell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  style: PropTypes.shape(),
  textStyle: PropTypes.shape({}),
  justify: PropTypes.string,
  preset: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
};

export default Cell;
