import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Shadow, Typography } from '@pitchero/react-ui';

const RowCell = ({
  isHighlightedRow,
  align,
  className,
  style,
  children,
  url,
  horizontalDivide,
  typographyPreset,
  typographyColor,
}) => {
  const color = url ? 'primary' : typographyColor;
  return (
    <Shadow shadow={isHighlightedRow ? 'row' : 'none'}>
      <Rectangle
        fill="white"
        style={{
          position: isHighlightedRow ? 'relative' : 'static',
          whiteSpace: 'nowrap',
          ...style,
        }}
        borderBottom={horizontalDivide ? 'mediumAltoDashed' : 'thinLightGrey'}
      >
        <Cushion horizontal="xsmall" className={className}>
          <Typography
            preset={typographyPreset}
            color={color}
            align={align}
            lineHeight="60px"
            component="div"
          >
            {children}
          </Typography>
        </Cushion>
      </Rectangle>
    </Shadow>
  );
};

RowCell.defaultProps = {
  align: 'center',
  className: null,
  isHighlightedRow: false,
  style: {},
  url: null,
  horizontalDivide: null,
  typographyPreset: 'subtitle--small',
  typographyColor: 'dustygrey',
};

RowCell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isHighlightedRow: PropTypes.bool,
  style: PropTypes.shape(),
  url: PropTypes.string,
  horizontalDivide: PropTypes.string,
  typographyPreset: PropTypes.string,
  typographyColor: PropTypes.string,
};

export default RowCell;
