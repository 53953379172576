import React from 'react';
import PropTypes from 'prop-types';
import Table from './index';
import TableV2 from './v2';

const CondensedTable = ({ columns, isV2, isMobile, ...otherProps }) => {
  if (isV2) {
    const allowedColumnTypes = ['name', 'played', 'points'];
    const hiddenOnMobileKeys = ['for', 'against'];
    const allowedColumnKeys = [
      'played',
      'won',
      'drew',
      'lost',
      'for',
      'against',
      'diff',
      'points',
    ].filter((key) => !isMobile || (isMobile && !hiddenOnMobileKeys.includes(key)));
    return (
      <TableV2
        columns={columns.filter(
          (column) =>
            allowedColumnKeys.includes(column.key.toString().toLowerCase()) ||
            allowedColumnTypes.includes(column.type.toString().toLowerCase()),
        )}
        isMobile={isMobile}
        {...otherProps}
      />
    );
  }
  return <Table columns={columns.filter((column) => column.show_in_condensed)} {...otherProps} />;
};

CondensedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      show_in_condensed: PropTypes.bool,
    }),
  ).isRequired,
  isV2: PropTypes.bool,
  isMobile: PropTypes.bool,
};

CondensedTable.defaultProps = {
  isV2: false,
  isMobile: false,
};

export default CondensedTable;
