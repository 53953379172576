import React from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { Badge, Grid, Hide, Rectangle, TextOverflow } from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import HeaderCell from './header-cell';
import RowCell from './row-cell';

const BREAKPOINT = 450;

const Table = ({ columns, rows, style }) => {
  if (rows.length === 0) {
    return null;
  }

  const populatedRows = rows.filter((row) => row.column_values.length > 0);
  if (populatedRows.length === 0) {
    return null;
  }

  let pointsColumn = get(find(columns, { type: 'points' }), 'key');
  if (!pointsColumn) {
    pointsColumn = get(
      find(columns, (column) => column.key !== 'team_name'),
      'key',
      'pts',
    );
  }

  const filteredColumns = columns.filter(
    (column) => column.key !== 'team_name' && column.key !== pointsColumn,
  );

  const gridTemplateRows = `48px repeat(${rows.length}, 60px)`;

  const wrapWithLink = (isHighlightedRow, url, element) => {
    if (isHighlightedRow || !url) {
      return element;
    }
    return <a href={url}>{element}</a>;
  };

  return (
    <Grid
      className="grid-league-table"
      rows={gridTemplateRows}
      columns="60px 40px 1fr auto 60px"
      responsive={[
        { maxWidth: BREAKPOINT, props: { columns: '40px 0 minmax(20%, 60%) auto 40px' } },
      ]}
      style={{
        ...style,
        msGridRows: `48px ${rows.map(() => '60px').join(' ')}`,
      }}
    >
      <Rectangle fill="alabaster" radius="roundedTopLeft">
        <HeaderCell style={{ msGridColumn: '1' }}>
          <Trans i18nKey="teams:table_position_col_header">Pos</Trans>
        </HeaderCell>
      </Rectangle>
      <HeaderCell style={{ msGridColumn: '2' }} align="left" colSpan={2}>
        <Trans i18nKey="teams:table_team_col_header">Team</Trans>
      </HeaderCell>
      <Rectangle style={{ msGridColumn: '3' }} fill="alabaster" radius="roundedTopRight">
        <HeaderCell style={{ gridColumnStart: 5 }}>Pts</HeaderCell>
      </Rectangle>
      {rows.map((row, index) => {
        const name = find(row.column_values, { key: 'team_name' });
        const points = get(find(row.column_values, { key: pointsColumn }), 'value');
        const { website_url: url, horizontal_divide: horizontalDivide } = row;
        const firstCellStyle = {
          gridColumnStart: 1,
          msGridColumn: '1',
          msGridRow: `${index + 2}`,
        };
        const lastCellStyle = {
          gridColumnStart: 5,
          msGridColumn: '3',
          msGridRow: `${index + 2}`,
        };

        if (row.is_highlighted) {
          firstCellStyle.marginLeft = -8;
          firstCellStyle.paddingLeft = 18;
          firstCellStyle.borderRadius = '4px 0 0 4px';
          lastCellStyle.marginRight = -8;
          lastCellStyle.paddingRight = 18;
          lastCellStyle.borderRadius = ' 0 4px 4px 0';
        }
        return (
          <React.Fragment key={row.rank}>
            <RowCell
              isHighlightedRow={row.is_highlighted}
              style={firstCellStyle}
              horizontalDivide={horizontalDivide}
              typographyPreset="subtitle--small"
              typographyColor="black"
            >
              {row.rank}
            </RowCell>
            <RowCell
              className="league-table__badge"
              isHighlightedRow={row.is_highlighted}
              url={url}
              horizontalDivide={horizontalDivide}
            >
              <Hide maxWidth={BREAKPOINT} component="div">
                <Badge
                  primary={row.primary_colour}
                  secondary={row.secondary_colour}
                  size={28}
                  style={{ verticalAlign: 'middle' }}
                />
              </Hide>
            </RowCell>
            <RowCell
              isHighlightedRow={row.is_highlighted}
              align="left"
              url={url}
              horizontalDivide={horizontalDivide}
              typographyPreset="body--small"
              typographyColor="dustygrey"
              style={{
                msGridColumn: '2',
                msGridRow: `${index + 2}`,
              }}
            >
              {wrapWithLink(
                row.is_highlighted,
                url,
                <TextOverflow maxWidth="100%">{name.value}</TextOverflow>,
              )}
            </RowCell>
            <RowCell
              isHighlightedRow={row.is_highlighted}
              style={lastCellStyle}
              horizontalDivide={horizontalDivide}
              typographyPreset="subtitle--small"
              typographyColor="black"
            >
              {points}
            </RowCell>
          </React.Fragment>
        );
      })}
      <div
        className="league-table__extra"
        style={{
          gridRow: `1 / span ${rows.length + 1}`,
          gridColumnStart: 4,
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        <Grid columns={`repeat(${filteredColumns.length}, 60px)`} rows={gridTemplateRows}>
          {filteredColumns.map((column) => (
            <HeaderCell
              key={column.key}
              align={column.key === 'team_name' ? 'left' : 'center'}
              colSpan={column.key === 'team_name' ? 2 : 1}
            >
              {column.short_label}
            </HeaderCell>
          ))}
          {rows.map((row) =>
            filteredColumns.map((column) => {
              const rowCol = find(row.column_values, { key: column.key });
              const { horizontal_divide: horizontalDivide } = row;
              return (
                <RowCell
                  isHighlightedRow={row.is_highlighted}
                  key={`${row.id}-${column.key}`}
                  horizontalDivide={horizontalDivide}
                  typographyPreset="body--small"
                  typographyColor="dustygrey"
                >
                  {rowCol ? rowCol.value : 0}
                </RowCell>
              );
            }),
          )}
        </Grid>
      </div>
    </Grid>
  );
};

Table.defaultProps = {
  style: {},
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      show_in_condensed: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_highlighted: PropTypes.bool.isRequired,
      primary_colour: PropTypes.string.isRequired,
      secondary_colour: PropTypes.string.isRequired,
      column_values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        }),
      ),
    }),
  ).isRequired,
  style: PropTypes.shape(),
};

export default Table;
