import PropTypes from 'prop-types';

export const FIXTURE_TEAM_PROP_TYPE = PropTypes.shape({
  name: PropTypes.string,
  score: PropTypes.string,
  primaryColour: PropTypes.string,
  secondaryColour: PropTypes.string,
});

export const FIXTURE_PROP_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  teamId: PropTypes.number,
  type: PropTypes.string,
  status: PropTypes.string,
  isLive: PropTypes.bool.isRequired,
  isCancelledOrPostponed: PropTypes.bool.isRequired,
  isCricket: PropTypes.bool.isRequired,
  competitionId: PropTypes.string,
  homeSide: FIXTURE_TEAM_PROP_TYPE.isRequired,
  awaySide: FIXTURE_TEAM_PROP_TYPE.isRequired,
  closestFixture: PropTypes.bool,
});

export const FIXTURES_PROP_TYPE = PropTypes.arrayOf(FIXTURE_PROP_TYPE);
