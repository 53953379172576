import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Typography } from '@pitchero/react-ui';

const HeaderCell = ({ align, children, colSpan, style }) => (
  <Cushion
    className="league-table__header"
    horizontal="xsmall"
    top={16}
    style={{ gridColumn: `span ${colSpan}`, overflow: 'hidden', ...style }}
  >
    <Rectangle fill="alabaster" borderBottom="thinLightGrey">
      <Typography preset="description" color="dustygrey" align={align} component="div">
        {children}
      </Typography>
    </Rectangle>
  </Cushion>
);

HeaderCell.defaultProps = {
  align: 'center',
  colSpan: 1,
  style: {},
};

HeaderCell.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  colSpan: PropTypes.number,
  style: PropTypes.shape(),
};

export default HeaderCell;
